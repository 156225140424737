import BasePlayScene from '../BaseFinder/PlayScene'

export default class PlayScene extends BasePlayScene {
  preload () {
    this.load.image('bg', '/static/games/game3/bg.jpg');

    if (this.sys.game.device.os.desktop){
      this.load.spritesheet('bg_anim', '/static/games/game3/bg_anim.png',{ frameWidth: 1600, frameHeight: 900 });
    } else {
      this.load.image('bg_anim', '/static/games/game3/bg_noanim.png');
    }
    this.load.image('dropzone', '/static/games/game3/dropzone.png');
    this.load.audio('bg_audio', '/static/games/game3/bg.mp3');

    this.corrects = [
      { name:'3-1.png', x:888, y: 238, drop_x:852, drop_y:794, width: Math.floor(385/3), height: 88, drop_scale: 1, title: "ช้อนสแตนเลส" },
      { name:'3-2.png', x:478, y:546, drop_x:436, drop_y:785, width:  Math.floor(673/3), height: 113, drop_scale: 0.5, title: "เข็มฉีดยาสะอาด" },
      { name:'3-3.png', x:88, y:337, drop_x:716, drop_y:779, width:  Math.floor(530/3), height: 111, drop_scale: 0.7, title: "สายรัด" },
      { name:'3-4.png', x:909, y:324, drop_x:566, drop_y:769, width: Math.floor(520/3), height: 86, drop_scale: 1, title: "น้ำกลั่น" },
      { name:'3-5.png', x:1075, y:304, drop_x:970, drop_y:782, width:  Math.floor(393/3), height: 78, drop_scale: 1 , title: "แผ่นแอลกอฮอล์"},
      { name:'3-6.png', x:744, y:520, drop_x:315, drop_y:769, width:  Math.floor(700/3), height: 210, drop_scale: 0.45, title: "ถุงสำลี" }
    ]

    this.wrongs = [
      { name:'3-7.png', x:1043, y:370, width: Math.floor(228/3), height: 102 },
      { name:'3-8.png', x:748, y:398, width: Math.floor(278/3), height: 133 },
      { name:'3-9.png', x:830, y:308, width: Math.floor(290/3), height: 58 },
      { name:'3-10.png', x:454, y:410, width: Math.floor(298/3), height: 93 },
      { name:'3-11.png', x:1164, y:92, width: Math.floor(723/3), height: 310 },
      { name:'3-12.png', x:1000, y:133, width: Math.floor(438/3), height: 102 },
      { name:'3-13.png', x:658, y:376, width: Math.floor(343/3), height: 130 },
      { name:'3-14.png', x:421, y:291, width: Math.floor(680/3), height: 99 },
      { name:'3-15.png', x:302, y:465, width: Math.floor(470/3), height: 84 },
    ]

    this.corrects.forEach((item,index)=>{
      this.load.spritesheet('correct-'+index, '/static/games/game3/objects/correct/'+item.name,{ frameWidth: item.width, frameHeight: item.height });
    })

    this.wrongs.forEach((item,index)=>{
      this.load.spritesheet('wrong-'+index, '/static/games/game3/objects/wrong/'+item.name,{ frameWidth: item.width, frameHeight: item.height });
    })

    super.preload();
  }

  init(data) {
    this.ended = false
    this.timer = 60000
    this.score = 0
    this.total_score = 6
    this.dropzone_w = 900
  }
}
